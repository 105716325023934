import { Tooltip as AntTooltip, Button, Popover } from 'antd'
import classNames from 'classnames'
import { format } from 'date-fns'
import React from 'react'

import { useScopedIntl } from '../../../../hooks'
import { Feature, Template, TemplateType } from '../../../../requests'
import { DatacIcon } from '../../../common'

interface TextCellProps {
  content: string
  className?: string
}

const TextCell: React.FC<TextCellProps> = ({ content, className }) => {
  return (
    <AntTooltip title={content}>
      <span className={className}>{content}</span>
    </AntTooltip>
  )
}

interface TypeCellProps {
  type: TemplateType
  feature: Feature
}

const TypeCell: React.FC<TypeCellProps> = ({ type, feature }) => {
  const intlTemplateType = useScopedIntl('settings.templates.template_type')
  const typesIntlKeys = {
    [TemplateType.Full]: feature.toLowerCase(),
    [TemplateType.Section]: 'section',
    [TemplateType.Subsection]: 'subsection',
    [TemplateType.Block]: 'block'
  }

  return (
    <TextCell
      content={intlTemplateType(typesIntlKeys[type])}
      className={classNames('settings-templates-table__type-column', {
        'settings-templates-table__type-column--full': type === TemplateType.Full,
        'settings-templates-table__type-column--section': type === TemplateType.Section,
        'settings-templates-table__type-column--subsection': type === TemplateType.Subsection,
        'settings-templates-table__type-column--block': type === TemplateType.Block
      })}
    />
  )
}

interface ActionsCellProps {
  onDelete: () => void
  onExport: () => void
}

const ActionsCell: React.FC<ActionsCellProps> = ({ onDelete, onExport }) => {
  const intlTemplatesTable = useScopedIntl('settings.templates.table')

  return (
    <div className="settings-templates-table__actions-column">
      {!!onDelete && (
        <AntTooltip title={intlTemplatesTable('delete')}>
          <Button className="datac-button--icon-only" onClick={onDelete}>
            <DatacIcon name="trash" />
          </Button>
        </AntTooltip>
      )}
      <AntTooltip title={intlTemplatesTable('export')}>
        <Button className="datac-button--icon-only" onClick={onExport}>
          <DatacIcon name="download" />
        </Button>
      </AntTooltip>
    </div>
  )
}

const tagsPopoverContent = (tags: string[]) => <div className="settings-templates-table__tags">{tags.join(', ')}</div>

const TagsCell: React.VFC<{ tags: string[] }> = ({ tags }) => {
  if (!tags) return <> - </>

  return tags.length < 3 ? (
    <>{tags.join(', ')}</>
  ) : (
    <Popover content={tagsPopoverContent(tags)} placement="bottomLeft" trigger="hover">
      {tags.slice(0, 2).join(', ')} ...
    </Popover>
  )
}

interface GetColumnsOptions {
  columnNames: {
    title: string
    description: string
    tags: string
    type: string
    createdDate: string
    ownerName: string
    actions: string
  }
  onEdit?: (template: Template) => void
  onDelete?: (template: Template) => void
  onExport?: (template: Template) => void
  feature: Feature
}

export const getColumns = ({ columnNames, onEdit, onDelete, onExport, feature }: GetColumnsOptions) => [
  {
    title: columnNames.title,
    dataIndex: 'title',
    key: 'title',
    ellipsis: true,
    sorter: true,
    width: '20%',
    render: (title: string) => <TextCell content={title} className="settings-templates-table__title-column" />
  },
  {
    title: columnNames.description,
    dataIndex: 'description',
    key: 'description',
    sorter: true,
    render: (description: string) => (
      <TextCell content={description} className="settings-templates-table__description-column" />
    )
  },
  {
    title: columnNames.tags,
    dataIndex: 'tags',
    key: 'tags',
    ellipsis: true,
    sorter: false,
    width: '15%',
    render: (tags: string[]) => <TagsCell tags={tags} />
  },
  {
    title: columnNames.type,
    dataIndex: 'type',
    key: 'type',
    ellipsis: true,
    sorter: true,
    width: '15rem',
    render: (type: TemplateType) => <TypeCell type={type} feature={feature} />
  },
  {
    title: columnNames.createdDate,
    dataIndex: 'createdDate',
    key: 'createdDate',
    ellipsis: true,
    sorter: true,
    width: '13%',
    render: (title: Date) => <TextCell content={format(title, 'dd MMM yyyy')} />
  },
  {
    title: columnNames.ownerName,
    dataIndex: 'ownerName',
    key: 'ownerName',
    ellipsis: true,
    sorter: true,
    width: '15%',
    render: (ownerName: string) => <TextCell content={ownerName} />
  },
  {
    title: columnNames.actions,
    dataIndex: 'actions',
    key: 'actions',
    width: '15rem',
    render: (_: null, template: Template) => (
      <ActionsCell
        onDelete={onDelete ? () => onDelete(template) : null}
        onExport={onExport ? () => onExport(template) : null}
      />
    )
  }
]
