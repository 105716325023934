import './TemplateTags.less'

import { Button, Checkbox, Form, Input, Popover } from 'antd'
import React, { useEffect, useState } from 'react'

import { useScopedIntl } from '../../../../hooks'
import { fetchTags } from '../../../../requests'
import { DatacIcon, DatacMessage } from '../../../common'

interface TemplateTagProps {
  tag: string
  remove?: () => void
}

export const TemplateTag: React.VFC<TemplateTagProps> = ({ tag, remove }) => (
  <div className="template-tags__tag">
    {tag}
    {remove && <DatacIcon name="x" raw onClick={remove} />}
  </div>
)

interface TemplateTagsProps {
  onSubmit: (selectedTags: string[]) => void
  selectedTags: string[]
}

export const TemplateTags: React.VFC<TemplateTagsProps> = ({ onSubmit, selectedTags }) => {
  const intl = useScopedIntl('')
  const intlTags = useScopedIntl('settings.templates.tags')
  const [formInstance] = Form.useForm()
  const [tagsOptions, setTagsOptions] = useState<string[]>([])
  const [isTagsSelectorOpened, setIsTagsSelectorOpened] = useState(false)
  const [filteredTagsOptions, setFilteredTagsOptions] = useState<string[]>([])
  const [currentSelectedTags, setCurrentSelectedTags] = useState<string[]>([])

  useEffect(() => {
    fetchTags(
      {},
      {
        onSuccess: tags => {
          setTagsOptions(tags)
          setFilteredTagsOptions(tags)
        },
        onRequestError: () => DatacMessage.genericError(intl)
      }
    )
  }, [])

  useEffect(() => {
    setCurrentSelectedTags(selectedTags)
  }, [selectedTags])

  const onPopoverVisibleChange = (isOpened: boolean) => {
    setIsTagsSelectorOpened(isOpened)
    formInstance.resetFields()
    onSearchChange('')
  }

  const onSubmitOptions = () => {
    const newTags = [...currentSelectedTags, ...(formInstance.getFieldValue('options') || [])]
    onSubmit(newTags)
    setCurrentSelectedTags(newTags)
    setIsTagsSelectorOpened(false)
  }

  const onSearchChange = (search: string) => {
    setFilteredTagsOptions(
      tagsOptions.filter(option => !currentSelectedTags.includes(option) && option.indexOf(search) !== -1)
    )
  }

  const onRemoveTag = (tag: string) => {
    const newTags = currentSelectedTags.filter(currentTag => currentTag !== tag)
    setCurrentSelectedTags(newTags)
    onSubmit(newTags)
  }

  const popoverContent = (
    <div className="template-tags__popover-dropdown">
      <Form
        name="template-tags__popover-form"
        className="template-tags__popover-form"
        form={formInstance}
        initialValues={{ variable: '', search: '' }}
      >
        <div className="template-tags__popover-search">
          <DatacIcon raw name="search" />
          <Form.Item name="search">
            <Input
              className="template-tags__popover-search-input"
              placeholder={intlTags('search_placeholder')}
              onChange={e => onSearchChange(e.currentTarget.value)}
            />
          </Form.Item>
        </div>
        <Form.Item name="options" dependencies={['search']} className="template-tags__popover-options">
          <Checkbox.Group options={filteredTagsOptions} />
        </Form.Item>
      </Form>
      <div className="template-tags__popover-controls">
        <Button size="middle" type="default" onClick={() => setIsTagsSelectorOpened(false)}>
          {intl('common.cancel')}
        </Button>
        <Button size="middle" type="primary" onClick={onSubmitOptions}>
          {intl('common.apply')}
        </Button>
      </div>
    </div>
  )

  return (
    <div className="template-tags">
      {currentSelectedTags.map((tag, index) => (
        <TemplateTag tag={tag} remove={() => onRemoveTag(tag)} key={index} />
      ))}
      <Popover
        content={popoverContent}
        trigger="click"
        placement="bottomLeft"
        open={isTagsSelectorOpened}
        onOpenChange={onPopoverVisibleChange}
        defaultOpen={false}
        className="template-tags__new"
        destroyTooltipOnHide
      >
        <DatacIcon name="plus" raw /> {intlTags('filter_by_tags')}
      </Popover>
    </div>
  )
}
