import './SettingsTemplatesImportModal.less'

import { Form } from 'antd'
import React, { useState } from 'react'

import { useScopedIntl } from '../../../../hooks'
import { importTemplate } from '../../../../requests'
import { DatacFileUpload, DatacLoading, DatacModal, OnDatacFileUpload } from '../../../common'

const maxFilesNumber = 20

interface Props {
  isOpened: boolean
  onClose: () => void
}

export const SettingsTemplatesImportModal: React.VFC<Props> = ({ isOpened, onClose }) => {
  const intlTemplates = useScopedIntl('settings.templates')
  const intl = useScopedIntl('')
  const [formInstance] = Form.useForm()
  const [numberOfFilesBeingImported, setNumberOfFilesBeingImported] = useState<number>(0)

  const onFileUpload: OnDatacFileUpload = options => {
    setNumberOfFilesBeingImported(currentFilesNumber => currentFilesNumber + 1)
    importTemplate(
      { file: options.file },
      {
        onSuccess: savedFile => {
          options.onSuccess(savedFile)
          setNumberOfFilesBeingImported(currentFilesNumber => currentFilesNumber - 1)
        },
        onRequestError: code => {
          options.onError(String(code))
          setNumberOfFilesBeingImported(currentFilesNumber => currentFilesNumber - 1)
        }
      }
    )
  }

  return (
    <DatacModal
      title={intlTemplates('import')}
      isOpened={isOpened}
      onClose={onClose}
      className="settings-templates-import-modal"
      cancelLabel={intl('common.close')}
      noClose={numberOfFilesBeingImported > 0}
      afterClose={() => {
        formInstance.resetFields()
      }}
      noSubmit
    >
      <div className="settings-templates-import-modal__content">
        <DatacLoading isLoading={numberOfFilesBeingImported > 0} transparent>
          <Form form={formInstance}>
            <Form.Item name="file">
              <DatacFileUpload
                onFileUpload={onFileUpload}
                showNotifications
                supportedExtensions={['json']}
                maxFilesNumber={maxFilesNumber}
              />
            </Form.Item>
          </Form>
        </DatacLoading>
      </div>
    </DatacModal>
  )
}
